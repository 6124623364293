/* 
 * @Author: 曹俊杰 
 * @Date: 2022-05-10 18:47:09
 * @Module: 三要素查询
 */
 <template>
  <div class="inspiectSearch">
    <div class="content">
      <h1>核查身份信息</h1>
      <el-form :model="form" ref="form" style="margin-top:20px;" :rules="rules">
        <el-form-item prop="name">
          <el-input v-model="form.name" placeholder="请输入被查询姓名"></el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <el-input v-model="form.phone" placeholder="请输入被查询人手机号"></el-input>
        </el-form-item>
        <el-form-item prop="idNumber">
          <el-input v-model="form.idNumber" placeholder="请输入被查询人身份证号 "></el-input>
        </el-form-item>
        <el-form-item prop="verifyCode">
          <div style="display:flex;">
            <el-input v-model="form.verifyCode" placeholder="请输入验证码"></el-input>
            <el-button type="" style="margin-left:14px;width:110px" @click="getcode">{{secondrender}}</el-button>
          </div>
        </el-form-item>
      </el-form>
      <el-link type="primary" @click="noCode">无法收到验证码？</el-link>
      <div>
        <el-button type="primary" :disabled="disabled" style="margin-top:20px;width:100%;" @click="postVerification" :loading="loading">立即核查</el-button>
      </div>
    </div>
  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    const phoneValidator = (_, value, callback) => {
      if (this.$utils.test.mobile(value)) {
        return callback();
      } else {
        return callback(new Error('请输入正确的手机号'));
      }
    }
    const idNumberValidator = (_, value, callback) => {
			console.log(value)
      if (this.$utils.test.idCardStrict(value)) {
        return callback();
      } else {
        return callback(new Error('请输入正确的身份证号'));
      }
    }
    return {
      second: 0,
      loading: false,
      form: {
        name: '',
        phone: '',
        idNumber: '',
        verifyCode: ''
      },
      rules: {
        name: [{ required: true, message: '请输入被查询姓名', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入被查询人手机号', trigger: 'blur' }, { validator: phoneValidator, trigger: 'blur' }],
        idNumber: [{ required: true, message: '请输入被查询人身份证号', trigger: 'blur' }, { validator: idNumberValidator, trigger: 'blur' }],
        verifyCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 4, max: 4, message: '请输入正确的验证码', trigger: 'blur' }
        ],
      }
    };
  },
  computed: {
    disabled () {
      return this.$utils.test.empty(this.form.name) || this.$utils.test.empty(this.form.phone) || this.$utils.test.empty(this.form.idNumber) || this.$utils.test.empty(this.form.verifyCode);
    },
    secondrender () {
      return this.second == 0 ? '获取验证码' : this.second + 's';
    }
  },
  mounted () { },
  methods: {
    secondrun () {
      let js = setInterval(() => {
        this.second--;
        if (this.second == 0) {
          clearInterval(js);
        }
      }, 1000);
    },
    validateField (arr = []) {
      return new Promise((resolve, reject) => {
        this.$refs.form.validateField(arr, res => {
          resolve(!res)
        })
      })
    },
    async getcode () {
      if (this.second !== 0) {
        return;
      }
      const verify = await this.validateField(['phone']);
      if (verify) {
        const { status, info } = await this.$api.postSmsCodeVerification({ phone: this.form.phone });
        if (status == 200) {
          this.$message({
            message: info,
            type: 'success'
          });
          this.second = 60;
          this.secondrun();
        } else {
          this.$message({
            message: info,
            type: 'error'
          });
        }
      }
    },
    alert () {
      return new Promise((resolve, reject) => {
        this.$alert('<span>个人风险报告核查系统每次核查<span style="color:#0062FF;">将花费15币</span> </span>', '消费提示', {
          confirmButtonText: '确定',
          dangerouslyUseHTMLString: 'true',
          showCancelButton: 'true',
          type: 'info',
          callback: action => {
            if (action === 'confirm') {
              resolve()
            } else {
              reject()
            }
          }
        });
      })
    },
    async postVerification () {
      this.loading = true
      try {
        await this.$refs.form.validate()
        await this.alert()
        const { status, data, info } = await this.$api.postVerification(this.form)
        if (status === 200) {
          this.$message({
            type: 'success',
            message: info
          })
          this.$router.push({
            path: '/inspect/inspectResult',
            query: {
              fileUrl: data.fileUrl
            }
          })
        } else {
          this.$message({
            type: 'error',
            message: info
          })
        }
        this.loading = false;

      } catch (e) {
        console.log('error', e)
        this.loading = false
      }
    },
    noCode () {
      this.$alert('如无法获取验证码，请联系对接法务或客服。', '帮助', {
        confirmButtonText: '确定'
      });
    }
  },
};
 </script>
 <style lang='scss' scoped>
.inspiectSearch {
  background: #fff;
  min-height: calc(100vh - 120px - 28px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
  background-image: url(~@/static/image/inspect_bg_1.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: top center;
  .content {
    width: 440px;
    background: #ffffff;
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    border: 1px solid #e3e3e3;
    margin-top: 175px;
    padding: 30px;
    h1 {
      text-align: center;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #262626;
      line-height: 30px;
    }
  }
}
</style>